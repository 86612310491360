import React, { ReactNode } from 'react'
import saxendaGrey from '../../../assets/images/saxenda-grey-bg.png'
import saxendaGreen from '../../../assets/images/saxenda-green-bg-w-cercle.png'
import { Button } from 'components'
import { SAXENDA_PRICE } from '../../../constants/prescriptions'
import {
  PrescriptionProgramCardPrimary,
  PrescriptionProgramCardSecondary,
} from '../PrescriptionProgram/PrescriptionProgramCard'

const noProgram = {
  title: 'En pharmacie',
  description: 'Vous vous déplacez et achetez vos médicaments en pharmacie',
  price: `${SAXENDA_PRICE}€/mois*`,
  priceDetails: '*prix moyen constaté en pharmacie',
  pillArgument: '-8% de masse sur 1 an',
  image: saxendaGrey,
  alt: 'saxenda',
  argumentList: [
    { available: false, text: 'Remboursement par la sécurité sociale' },
    { available: false, text: 'Accompagnement quotidien et personnalisé (questions, effets secondaires)' },
    { available: false, text: 'Livraison des traitements à domicile en 48h' },
    { available: false, text: 'Programme nutrition (plan de perte de poids et menus)' },
    { available: false, text: 'Suivi médical du poids et des doses' }
  ],
}

const bodyResetProgram = {
  title: 'Avec le programme Body Reset',
  description: 'Vos médicaments livrés chez vous et nos services de suivi inclus',
  price: `${SAXENDA_PRICE}€/mois`,
  pillArgument: '-12% de masse sur 1 an',
  image: saxendaGreen,
  alt: 'saxenda-body-reset',
  argumentList: [
    { available: false, text: 'Remboursement par la sécurité sociale' },
    { available: true, text: 'Accompagnement quotidien et personnalisé (questions, effets secondaires)' },
    { available: true, text: 'Livraison des traitements à domicile en 48h' },
    { available: true, text: 'Programme nutrition (plan de perte de poids et menus)' },
    { available: true, text: 'Suivi médical du poids et des doses' }
  ],
}

type PrescriptionProgramProps = {
  handleDownloadPrescription: () => void,
  handleOrderPrescription: () => void,
}

const SaxendaProgram = ({ handleDownloadPrescription, handleOrderPrescription }: PrescriptionProgramProps) => {
  return (
    <div className="d-flex flex-column flex-lg-row-reverse prescription-program-wrapper">
      <BodyResetProgramCard handleOrderPrescription={handleOrderPrescription} />
      <NoProgramCard handleDownloadPrescription={handleDownloadPrescription}  />
    </div>
  )
}

type NoProgramCardProps = {
  handleDownloadPrescription: () => void

}

const NoProgramCard = ({ handleDownloadPrescription }: NoProgramCardProps) => {
  const cta = (
    <div
      className="w-fit-content mx-auto mb-4 text-decoration-underline cursor-pointer"
      onClick={handleDownloadPrescription}
    >
      Je continue seul
    </div>)

  return (
    <PrescriptionProgramCardSecondary
      cta={cta}
      {...noProgram}
    />
  )
}

type BodyResetProgramCardProps = {
  handleOrderPrescription: () => void
}

const BodyResetProgramCard = ({ handleOrderPrescription }: BodyResetProgramCardProps) => {
  const cta = (
    <Button
      onClick={handleOrderPrescription}
      variant="btn-primary"
      classname="w-100"
    >
      Je continue avec Charles
    </Button>
  )

  return (
    <PrescriptionProgramCardPrimary
      cta={cta}
      {...bodyResetProgram}
    />
  )
}

export default SaxendaProgram
