import React, { ReactNode } from 'react'
import wegovyGrey from '../../../assets/images/wegovy-grey-bg.png'
import wegovyGreen from '../../../assets/images/wegovy-green-bg-w-cercle.png'
import { Button } from 'components'
import {
  PrescriptionProgramCardPrimary,
  PrescriptionProgramCardSecondary,
} from '../PrescriptionProgram/PrescriptionProgramCard'
import { WEGOVY_PRICE } from '../../../constants/prescriptions'

const noProgram = {
  title: 'En pharmacie (sans accompagnement)',
  description: 'Vous vous déplacez et achetez vos médicaments en pharmacie',
  price: `à partir de ${WEGOVY_PRICE}€/mois*`,
  priceDetails: '*prix moyen constaté en pharmacie',
  pillArgument: '-10% de masse sur 1 an',
  image: wegovyGrey,
  alt: 'wegovy',
  argumentList: [
    { available: false, text: 'Remboursement par la sécurité sociale' },
    { available: false, text: '10% de perte moyenne' },
    { available: false, text: 'Accompagnement quotidien et personnalisé par un coach dans notre app' },
    { available: false, text: 'Gestion des effets secondaires' },
    { available: false, text: 'Livraison des traitements à domicile en 48h' },
    { available: false, text: 'Programme nutrition en vidéo' }
  ],
}

const bodyResetProgram = {
  title: 'Avec le programme Body Reset',
  description: 'Vos médicaments livrés chez vous et nos services de suivi inclus',
  price: `à partir de ${WEGOVY_PRICE}€/mois`,
  pillArgument: '-20% de masse sur 1 an',
  image: wegovyGreen,
  alt: 'wegovy-body-reset',
  argumentList: [
    { available: false, text: 'Remboursement par la sécurité sociale' },
    { available: true, text: '20% de perte moyenne' },
    { available: true, text: 'Accompagnement quotidien et personnalisé par un coach dans notre app' },
    { available: true, text: 'Gestion des effets secondaires' },
    { available: true, text: 'Livraison des traitements à domicile en 48h' },
    { available: true, text: 'Programme nutrition en vidéo' }
  ],
}

type PrescriptionProgramProps = {
  handleDownloadPrescription: () => void,
  handleOrderPrescription: () => void,
}

const WegovyProgram = ({ handleDownloadPrescription, handleOrderPrescription }: PrescriptionProgramProps) => {
  return (
    <div className="d-flex flex-column flex-lg-row-reverse prescription-program-wrapper">
      <BodyResetProgramCard handleOrderPrescription={handleOrderPrescription} />
      <NoProgramCard handleDownloadPrescription={handleDownloadPrescription}  />
    </div>
  )
}

type NoProgramCardProps = {
  handleDownloadPrescription: () => void
}

const NoProgramCard = ({ handleDownloadPrescription }: NoProgramCardProps) => {
  const cta = (
    <div
      className="w-fit-content mx-auto mb-4 text-decoration-underline cursor-pointer"
      onClick={handleDownloadPrescription}
    >
      Je continue seul
    </div>)

  return (
    <PrescriptionProgramCardSecondary
      cta={cta}
      {...noProgram}
    />
  )
}

type BodyResetProgramCardProps = {
  handleOrderPrescription: () => void
}

const BodyResetProgramCard = ({ handleOrderPrescription }: BodyResetProgramCardProps) => {
  const cta = (
    <Button
      onClick={handleOrderPrescription}
      variant="btn-primary"
      classname="w-100"
    >
      Je continue avec Charles
    </Button>
  )

  return (
    <PrescriptionProgramCardPrimary
      cta={cta}
      {...bodyResetProgram}
    />
  )
}

export default WegovyProgram
