import { Block, Button, SvgIcon } from 'components'
import { Card, ListGroup } from 'react-bootstrap'
import { getIcon } from '../../../assets/icons'
import React from 'react'
import T from 'types'
import CardPill from '../../CardPill'
import cx from 'classnames'

const PrescriptionProgramCard = ({
  title,
  description,
  pillArgument,
  className,
  price,
  alt,
  image,
  argumentList,
  priceDetails,
  cta
}: T.PrescriptionCard) => {

  return (
    <CardPill text={pillArgument} className={cx('saxenda-card', className)}>
      <h3>{title}</h3>
      <div className="mb-2">{description}</div>
      <img className="flex-shrink-0 w-100 rounded mb-2" alt={alt} src={image} />
      <div className="flex">
        <div className="saxenda-card-price mb-2">{price}</div>
        <ListGroup className="saxenda-card-list">
          {argumentList.map((argument, index) => (
            <div key={index} className="d-flex mb-3 saxenda-card-list-argument">
              <SvgIcon classname="saxenda-card-list-argument-icon flex-shrink-0 icon" icon={getIcon(`${argument.available ? 'greenRoundCheck' : 'redRoundCross'}`)} />
              <div>{argument.text}</div>
            </div>
          ))}
        </ListGroup>
      </div>
      <div className="mt-auto">
        {cta}
        {priceDetails && <div className="font-italic">{priceDetails}</div>}
      </div>
    </CardPill>
  )
}

export const PrescriptionProgramCardPrimary = ({
  title,
  description,
  pillArgument,
  price,
  alt,
  image,
  argumentList,
  cta,
}: T.PrescriptionCard) => {

  return (
    <PrescriptionProgramCard
      title={title}
      description={description}
      pillArgument={pillArgument}
      price={price}
      alt={alt}
      image={image}
      argumentList={argumentList}
      cta={cta}
      className="green-card-pill green-prescription-card"
    />
  )
}

export const PrescriptionProgramCardSecondary = ({
  title,
  description,
  pillArgument,
  image,
  alt,
  argumentList,
  cta,
  price,
  priceDetails,
}: T.PrescriptionCard) => {

  return (
    <PrescriptionProgramCard
      title={title}
      description={description}
      pillArgument={pillArgument}
      image={image}
      alt={alt}
      argumentList={argumentList}
      price={price}
      priceDetails={priceDetails}
      cta={cta}
      className="grey-card-pill grey-prescription-card"
    />
  )
}
